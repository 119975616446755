import React from "react";
import Atlantis from "./components/Atlantis/Atlantis";
import { HashRouter as Router } from "react-router-dom";
const App = () => (
  <Router>
    <Atlantis />
  </Router>
);

export default App;
